import React from "react"
import styled, { withTheme, ThemeProps } from "styled-components"
import {
  Link,
  PreStyledComponents,
  Interfaces,
  IconDownload
} from "@life-without-barriers/react-components"
import { getGatsbyImage } from "@life-without-barriers/gatsby-common"
import marked from "marked"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

import Card from "./Card"

const { StandardButton } = PreStyledComponents

interface Props {
  title: string
  description: string
  resource: string
  thumbnail?: IGatsbyImageData
  theme: Interfaces.Theme
}

export default withTheme(
  ({
    title,
    description,
    resource,
    thumbnail,
    theme
  }: Props & ThemeProps<Interfaces.Theme>) => (
    <Card className="pa4">
      <div className="flex-ns tc tl-ns pa2-ns">
        {thumbnail && (
          <GatsbyImage
            image={getGatsbyImage(thumbnail)}
            alt={title}
            className="ba center b-lwb-grey-medium mr4-ns"
            style={{
              height: "100%",
              maxWidth: "130px",
              width: "100%"
            }}
          />
        )}
        <div className="pl2-ns">
          <h2 className="ts-display-4 fw8 mt3 mt0-ns">{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: marked(description) }} />
          <Link
            to={resource}
            title={`View PDF ${title}`}
            className="dib mt3 no-underline"
          >
            <StyledButton theme={theme}>
              View PDF
              <IconDownload className="ml2" height="14" width="12" />
            </StyledButton>
          </Link>
        </div>
      </div>
    </Card>
  )
)

const StyledButton = styled(StandardButton)`
  background-color: ${({ theme }: Interfaces.ThemedProps) => theme.medium};

  &:focus,
  &:hover {
    background-color: ${({ theme }: Interfaces.ThemedProps) => theme.dark};
  }
`
