import React from "react"
import { withTheme, ThemeProps } from "styled-components"
import {
  Interfaces,
  PhoneNumberCallout
} from "@life-without-barriers/react-components"
import { BLOCKS, Block, Inline, Text } from "@contentful/rich-text-types"
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text"

import Card from "./Card"
import { HorizontalRule } from "./SharedStyles"

interface Props {
  title: string
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>
  theme: Interfaces.Theme
}

const findCalloutPhone = (node: Block | Inline | Text): string | undefined => {
  if (node.nodeType === BLOCKS.PARAGRAPH) {
    const [nodeContent] = node.content
    return nodeContent.nodeType === "text" && nodeContent.value.startsWith("--")
      ? nodeContent.value.replace("--", "")
      : undefined
  }
  return undefined
}

const options = {
  renderNode: {
    [BLOCKS.HR]: () => <HorizontalRule className="mv4 bw0 bg-lwb-grey-dark" />,
    [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: React.ReactNode) => {
      const phoneCallout = findCalloutPhone(node)
      if (phoneCallout) {
        return <PhoneNumberCallout phoneNumber={phoneCallout} />
      }
      return children && <p className="copy">{children}</p>
    }
  }
}

export default withTheme(
  ({ title, body }: Props & ThemeProps<Interfaces.Theme>) => {
    return (
      <Card>
        <div className="pa4">
          <h2 className="ts-display-4 mt0 mv0-l fw8 color-lwb-theme-medium">
            {title}
          </h2>
          <div>{renderRichText(body, options)}</div>
        </div>
      </Card>
    )
  }
)
