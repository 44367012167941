import {
  ArticleCard,
  Banner,
  Helmet,
  IconSheets,
  Layout,
  PreStyledComponents,
  ResourcesTile,
  Theme,
  routesObject
} from "@life-without-barriers/react-components"
import {
  contentfulHelpers as Contentful,
  helpers,
  sendEvent
} from "@life-without-barriers/utilities"
import { Site, contentTypes as ct } from "@life-without-barriers/gatsby-common"

import Card from "../../components/news/Card"
import ContentfulRichText from "../../components/news/ContentfulRichText"
import { GatsbyImage } from "gatsby-plugin-image"
import LargeCTA from "../../components/news/LargeCTA"
import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

const { Container, GridWrapper } = PreStyledComponents
const { dasherize, truncate } = helpers
const { imageDescriptionParser } = Contentful

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    currentPage: ct.NewsArticle & {
      openGraphImage: Contentful.RemoteImageAsset
    }
    relatedArticles: {
      nodes: ct.NewsArticleSummary[]
    }
    lwbArticles: {
      nodes: ct.NewsArticleSummary[]
    }
  }
}

const ArticleTemplate = ({
  location,
  data: {
    site: { siteMetadata },
    currentPage,
    relatedArticles,
    lwbArticles
  }
}: Props) => {
  const pageData = currentPage
  const {
    title,
    introductionText,
    category,
    body,
    date,
    displayFeatureImageOnArticle,
    featureImage,
    resources,
    largeCta,
    openGraphImage
  } = pageData

  const imageDescription = imageDescriptionParser(featureImage.description)
  const FeatureImageCaption = () =>
    imageDescription.caption && imageDescription.caption.length > 0 ? (
      <p className="f6 tc">Image: {imageDescription.caption}</p>
    ) : null

  const articleCategory = dasherize(category).toLowerCase()
  const categoryTheme = Theme.getThemeFromCategory(articleCategory)

  const relatedStories = [...relatedArticles.nodes, ...lwbArticles.nodes].slice(
    0,
    3
  )

  return (
    <div>
      <Helmet
        title={`${title} | ${siteMetadata.title}`}
        description={introductionText.text}
        image={openGraphImage.gatsbyImageData}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        isArticle
        includeGoogleSchema
        articleDate={date}
      />
      <Layout theme={categoryTheme}>
        <Banner
          title={title}
          centered
          breadcrumbs={[
            routesObject.home,
            {
              to: "/news/",
              text: "News",
              title: "Return to news page"
            }
          ]}
          share={true}
        />
        <StyledContainer className="ph3 ph0-l">
          <div className="pt5-ns ph5-ns pt4 pb3">
            <div className="x-small ttu f7 f6-ns color-lwb-grey-darker tracked">
              {date}
            </div>
          </div>
          <ArticleIntro className="ts-display-5 fw7 ph5-ns mt0">
            {introductionText.text}
          </ArticleIntro>
          {displayFeatureImageOnArticle && (
            <div className="mv4">
              <GatsbyImage
                image={featureImage.gatsbyImageData}
                alt={imageDescription.alt}
              />
              <FeatureImageCaption />
            </div>
          )}
          <ContentfulRichText className="w-100" richTextBody={body} />
        </StyledContainer>
        {largeCta && (
          <LargeCTA
            heading={largeCta.heading}
            description={largeCta.description}
            url={largeCta.url}
            buttonText={largeCta.buttonLabel}
            theme={categoryTheme}
          />
        )}
        <StyledContainer className="mt5 mb4 ph3">
          {resources && (
            <Card>
              <ResourcesTile
                name="Resources"
                icon={<IconSheets height="25" color={categoryTheme.medium} />}
                resources={resources.map((r) => ({
                  name: r.title,
                  reference: r.file.url
                }))}
                theme={categoryTheme}
                onResourceClick={(resource) =>
                  sendEvent(
                    "News Article Resource",
                    "Click",
                    `${resource.name}`
                  )
                }
              />
            </Card>
          )}
          <HorizontalRule className="ma0 bw0 bg-lwb-grey-dark" aria-hidden />
        </StyledContainer>
        <Container className="ph3">
          <div>
            <div className="x-small ttu f7 f6-ns color-lwb-grey-darker tracked">
              {"Related Stories"}
            </div>
          </div>
          <GridWrapper className="mt2 mb5-ns flex-ns flex-wrap-ns">
            {relatedStories.map((article, index) => (
              <ArticleCard
                key={index}
                href={`/news/${article.slug}`}
                category={article.category}
                title={truncate(article.title, 65)}
                copy={truncate(article.introductionText.text, 110)}
                image={article.featureImage.gatsbyImageData}
              />
            ))}
          </GridWrapper>
        </Container>
      </Layout>
    </div>
  )
}

const StyledContainer = styled(Container)`
  max-width: 700px;
`

const HorizontalRule = styled.hr`
  height: 1px;
`

const ArticleIntro = styled.p`
  p& {
    line-height: 1.3em;
  }
`

export const articleRemoteAssetQuery = graphql`
  fragment ArticlePageRemoteContentfulAsset on ContentfulAsset {
    contentful_id
    __typename
    file {
      url
      fileName
      contentType
      details {
        size
        image {
          width
          height
        }
      }
    }
    title
    description
    gatsbyImageData(width: 1200)
  }
`

export const articleRemoteResourceQuery = graphql`
  fragment ArticlePageRemoteContentfulResource on ContentfulAsset {
    contentful_id
    __typename
    file {
      url
      fileName
      contentType
      details {
        size
      }
    }
    title
    description
  }
`

export const query = graphql`
  query ($id: String, $category: String) {
    currentPage: contentfulNewsArticle(id: { eq: $id }) {
      contentful_id
      __typename
      id
      title
      category
      largeCta {
        heading
        description
        url
        buttonLabel
      }
      introductionText {
        text: introductionText
      }
      date: date(formatString: "D MMMM YYYY")
      displayFeatureImageOnArticle
      featureImage {
        ...ArticlePageRemoteContentfulAsset
      }
      openGraphImage: featureImage {
        ...OpenGraphImageAssetQuery
      }
      body {
        raw
        references {
          ... on Node {
            ...RemoteContentfulAsset
            ...RemoteContentfulNewsCtaTextLink
            ...RemoteContentfulNewsEvent
            ...RemoteContentfulNewsImageLink
            ...RemoteContentfulNewsPublicationsCard
            ...RemoteContentfulNewsWhiteTextCard
            ...RemoteContentfulNewsYouTubeEmbed
            ...ContentfulImageCarousel
          }
        }
      }
      resources {
        ...ArticlePageRemoteContentfulResource
      }
    }
    relatedArticles: allContentfulNewsArticle(
      filter: {
        id: { ne: $id }
        category: { eq: $category }
        publishingOptions: { nin: "Hide on index" }
      }
      sort: { fields: [date], order: DESC }
      limit: 3
    ) {
      nodes {
        ...NewsArticleSummary
      }
    }
    lwbArticles: allContentfulNewsArticle(
      filter: {
        id: { ne: $id }
        category: { eq: "Life Without Barriers" }
        publishingOptions: { nin: "Hide on index" }
      }
      sort: { fields: [date], order: DESC }
      limit: 3
    ) {
      nodes {
        ...NewsArticleSummary
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default ArticleTemplate
