import React from "react"
import styled, { withTheme, ThemeProps } from "styled-components"
import {
  Link,
  Interfaces,
  IconArrowRight
} from "@life-without-barriers/react-components"
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"

interface Props {
  title: string
  ctaText: string
  newsArticleSlug: string
  image?: IGatsbyImageData
  theme: Interfaces.Theme
}

export default withTheme(
  ({
    title,
    ctaText,
    newsArticleSlug,
    image,
    theme
  }: Props & ThemeProps<Interfaces.Theme>) => {
    return (
      <StyledCardLink
        className="card no-underline black normal pointer lwb-shadow br2 mv4 flex flex-column flex-row-ns"
        to={`/news/${newsArticleSlug}`}
      >
        <div className="w-100 w-40-ns">
          {image && (
            <GatsbyImage
              alt={title}
              className="ba b-lwb-grey-medium"
              image={image}
              style={{
                height: "100%",
                width: "100% !important"
              }}
            />
          )}
        </div>
        <div className="w-100 w-60-ns">
          <TextWrapper className="pa4">
            <h2 className="card-title ts-display-4 mt0 mv0-l fw8">{title}</h2>
            <CtaText className="cta-link dib mt3 no-underline fw6">
              {ctaText}
              <IconArrowRight
                height="8"
                width="5"
                color={theme.dark}
                className="ml2"
              />
            </CtaText>
          </TextWrapper>
        </div>
      </StyledCardLink>
    )
  }
)

const TextWrapper = styled.div`
  padding: 1.5rem;
`

const CtaText = styled.div`
  color: ${({ theme }: Interfaces.ThemedProps) => theme.dark};
`
const StyledCardLink = styled(Link)`
  &:hover,
  &:focus {
    color: ${({ theme }: Interfaces.ThemedProps) => theme.dark};
  }

  .card-title {
    transition: color 200ms ease-in;
  }
`
