import React from "react"
import styled from "styled-components"
import { RenderNode } from "@contentful/rich-text-react-renderer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import {
  YouTubeVideo,
  Variables,
  media,
  Interfaces,
  EventCard,
  ContentfulRichTextImage,
  ArticleCTATextLink,
  ImageCarousel
} from "@life-without-barriers/react-components"
import {
  contentfulHelpers as Contentful,
  stripVideoIdFromYoutubeUrl
} from "@life-without-barriers/utilities"
import {
  BLOCKS,
  INLINES,
  Block,
  Inline,
  Text
} from "@contentful/rich-text-types"

import { contentTypes as ct } from "@life-without-barriers/gatsby-common"

import { HorizontalRule } from "./SharedStyles"
import DownloadCard from "./DownloadCard"
import NewsImageLink from "./NewsImageLink"
import NewsWhiteTextCard from "./NewsWhiteTextCard"

const { greyXXXLight, black } = Variables

interface HasTypeName {
  __typename: string
}

const isHasTypeName = (value: unknown): value is HasTypeName =>
  typeof value === "object" && value !== null && "__typename" in value

const findQuoteAttribution = (
  node: Block | Inline | Text
): string | undefined => {
  if (node.nodeType === BLOCKS.PARAGRAPH) {
    const [nodeContent] = node.content
    return nodeContent.nodeType === "text" && nodeContent.value.startsWith("--")
      ? nodeContent.value.replace("--", "")
      : undefined
  }
  return undefined
}

const StyledOL = styled.ol`
  margin-block-start: 0.5em;
  padding-left: 2rem;
  ${media.notSmall`
    padding-left: 6rem;
  `}
`

const StyledUL = styled.ul`
  margin-block-start: 0.5em;
  padding-left: 2rem;

  ${media.notSmall`
    padding-left: 6rem;
  `}
`

const StyledH2 = styled.h2`
  margin-top: 3rem;
`

const StyledH3 = styled.h3`
  font-size: 18px;
  margin-top: 3rem;
`

const nodeRenderers = (assets: Contentful.ContentfulAsset[]): RenderNode => ({
  [BLOCKS.HEADING_2]: (_, children) => (
    <StyledH2 className="ts-display-4 fw8 ph5-ns">{children}</StyledH2>
  ),
  [BLOCKS.HEADING_3]: (_, children) => (
    <StyledH3 className="fw8 ph5-ns">{children}</StyledH3>
  ),
  [BLOCKS.PARAGRAPH]: (_, children) => {
    return children && <p className="ph5-ns copy">{children}</p>
  },
  [BLOCKS.UL_LIST]: (_, children) => <StyledUL>{children}</StyledUL>,
  [BLOCKS.OL_LIST]: (_, children) => (
    <StyledOL className="ph5-ns">{children}</StyledOL>
  ),
  [BLOCKS.LIST_ITEM]: (_, children) => <li className="pv1">{children}</li>,
  [BLOCKS.HR]: () => <HorizontalRule className="mv5 bw0 bg-lwb-grey-x-light" />,
  [BLOCKS.QUOTE]: (node, children) => {
    const blockNode = node as Block
    const lastChild = blockNode.content[blockNode.content.length - 1]
    const attribution = findQuoteAttribution(lastChild)
    const blockquoteClassNames = "mh0 ph0 mv4"
    return attribution ? (
      <blockquote className={blockquoteClassNames}>
        {React.Children.toArray(children).slice(0, -1)}
        <p className="attribution ttu tracked">&mdash; {attribution}</p>
      </blockquote>
    ) : (
      <blockquote className={blockquoteClassNames}>{children}</blockquote>
    )
  },
  [BLOCKS.EMBEDDED_ASSET]: (node) => {
    const fields = node.data.target as
      | Contentful.RemoteImageAsset
      | Contentful.RemoteFileAsset
    if (!fields || !fields.file) {
      return
    }
    if ((fields as Contentful.RemoteImageAsset).gatsbyImageData) {
      return (
        <ContentfulRichTextImage
          fields={fields as Contentful.RemoteImageAsset}
          assets={assets}
        />
      )
    }
  },
  [BLOCKS.EMBEDDED_ENTRY]: (node) => {
    if (!isHasTypeName(node.data.target)) {
      return
    }
    const fields = node.data.target

    const { __typename } = fields

    if (__typename === "ContentfulNewsPublicationsCard") {
      const {
        title,
        markdown: { description },
        resource,
        thumbnail
      } = fields as ct.NewsDownloadResource
      if (!resource.file || !thumbnail.gatsbyImageData) {
        return
      }

      const {
        file: { url: resourceUrl }
      } = resource

      return (
        <DownloadCard
          title={title}
          description={description}
          resource={resourceUrl}
          thumbnail={thumbnail.gatsbyImageData}
        />
      )
    }

    if (__typename === "ContentfulNewsYouTubeEmbed") {
      const { title, url } = fields as ct.NewsYoutube
      const videoId = stripVideoIdFromYoutubeUrl(url) || ""
      return (
        <YouTubeVideo
          className="mv4"
          height={675}
          title={title}
          videoId={videoId}
        />
      )
    }
    if (__typename === "ContentfulNewsCtaTextLink") {
      const { ctaLinkLabel, ctaLinkUrl } = fields as ct.NewsCTATextLink
      return (
        <div className="mv3 ph5-ns">
          <ArticleCTATextLink label={ctaLinkLabel} url={ctaLinkUrl} />
        </div>
      )
    }
    if (__typename === "ContentfulNewsEvent") {
      const newsFields = fields as ct.NewsEvent
      const eventFields = {
        ...newsFields,
        location: newsFields.location.location,
        description: newsFields.markdown?.description || ""
      }
      return <EventCard {...eventFields} />
    }
    if (__typename === "ContentfulNewsWhiteTextCard") {
      const { title, body } = fields as ct.NewsWhiteTextCard
      return <NewsWhiteTextCard title={title} body={body} />
    }
    if (__typename === "ContentfulNewsImageLink") {
      const { title, ctaText, image, newsArticle } = fields as ct.NewsImageLink
      const { slug } = newsArticle

      return (
        <NewsImageLink
          title={title}
          ctaText={ctaText}
          newsArticleSlug={slug}
          image={image.gatsbyImageData}
        />
      )
    }

    if (__typename === "ContentfulImageCarousel") {
      const imageCarousel = fields as ct.ImageCarousel
      return (
        <ImageCarousel
          title={imageCarousel.title}
          className="pt3 mv4_5"
          images={imageCarousel.images}
          id={imageCarousel.contentful_id}
        />
      )
    }
  },
  [INLINES.ASSET_HYPERLINK]: (node, children) => {
    const fields = node.data.target as Contentful.RemoteFileAsset // not sure if this is the right type
    if (!fields || !fields.file) {
      return
    }

    const url = fields.file.url
    if (!url) {
      return
    }
    return <a href={url}>{children}</a>
  }
})

interface Props {
  richTextBody: ct.NewsArticleBody
  className?: string
}

const contentfulRichText = ({ richTextBody, className }: Props) => (
  <ContentfulRichText className={className}>
    {renderRichText(richTextBody, {
      renderNode: nodeRenderers(richTextBody.references)
    })}
  </ContentfulRichText>
)

export default contentfulRichText

const ContentfulRichText = styled.div`
  blockquote {
    color: ${({ theme }: Interfaces.ThemedProps) => theme.dark};

    p {
      font-size: 1.5rem;
      line-height: 1.4em;
      font-weight: 300;
      text-align: center;
      padding: 0;
    }

    p.attribution {
      font-size: 12px;
    }
  }

  ${media.notSmall`
    blockquote {
      p {
        font-size: 2.25rem;
        line-height: 1.4em;
        font-weight: 300;
      }
    }
  `}

  li {
    p {
      padding: 0;
      margin: 0;
    }
  }

  table {
    border-collapse: collapse;
    margin: 2rem 0;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    background-color: ${({ theme }: Interfaces.ThemedProps) => theme.xxxlight};
  }

  th,
  td {
    text-align: left;
    padding: 0.5rem 0.75rem;
    margin: 0;
    vertical-align: top;
  }

  tr:nth-child(even) {
    background-color: ${greyXXXLight};
  }

  a {
    :not(.cta-link) {
      color: ${black};
      &:hover {
        color: ${({ theme }: Interfaces.ThemedProps) => theme.dark};
      }
    }
  }
`
