import React from "react"
import styled from "styled-components"
import {
  Interfaces,
  Link,
  StandardButton,
  PreStyledComponents
} from "@life-without-barriers/react-components"

const { Container } = PreStyledComponents

interface LargeCTAProps {
  heading: string
  description: string
  buttonText: string
  url: string
  theme: Interfaces.Theme
}

const LargeCTA = ({
  heading,
  description,
  buttonText,
  url,
  theme
}: LargeCTAProps) => {
  return (
    <LargeCTAWrapper className="w-100">
      <StyledContainer theme={theme} className="pv3 mv5 tc">
        <h2 className="ts-display-4 fw8 ph4 mt4 tc">{heading}</h2>
        <p className="ph4 tc">{description}</p>
        <Link to={url} className="dib mv4 no-underline">
          <CTALinkButton theme={theme}>{buttonText}</CTALinkButton>
        </Link>
      </StyledContainer>
    </LargeCTAWrapper>
  )
}

const CTALinkButton = styled(StandardButton)`
  background-color: ${({ theme }: Interfaces.ThemedProps) => theme.medium};

  &:focus,
  &:hover {
    background-color: ${({ theme }: Interfaces.ThemedProps) => theme.dark};
  }
`

const LargeCTAWrapper = styled.div`
  background-color: ${({ theme }: Interfaces.ThemedProps) => theme.xxxlight};
`

const StyledContainer = styled(Container)`
  max-width: 700px;
`

export default LargeCTA
