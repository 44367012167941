export const dasherize = (str: string) =>
  str.replace(/\s/g, "-").replace(/,/g, "")

export const noop = () => {
  return
}

/**
 * Useful for validating certain cases shouldn't be possible.
 * When used as the default case in a switch statement it should
 * simulate exhaustive pattern matching.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const unreachable = (_: never) => {
  throw new Error(
    "An unreachable code path was executed. This shouldn't happen."
  )
}

export const hasFileExtension = (str: string, customPattern?: RegExp) => {
  const defaultPattern = /\.(pdf|jpe?g|gif|png|zip|doc?x|xls?x){1}$/i
  const pattern = customPattern ? customPattern : defaultPattern
  return pattern.test(str)
}
export const hasQueryString = (str: string) => {
  const pattern = /\?.+=.*/g
  return pattern.test(str)
}

export const isLocalUrl = (url: string) => /^\/(?!\/)/.test(url)

export const dasherizeAlphaNumericOnly = (str: string) =>
  str.replace(/[^a-zA-Z0-9]/g, "-")

export const truncate = (str: string, length: number) =>
  str && str.length > length ? str.substr(0, length) + "..." : str

export const isPdf = (url: string) => url.toLowerCase().endsWith(".pdf")

export const isZip = (url: string) => url.toLowerCase().endsWith(".zip")

export const isWordDoc = (url: string) =>
  url.toLowerCase().endsWith(".doc") || url.toLowerCase().endsWith(".docx")

export const isSpreadsheet = (url: string) =>
  url.toLowerCase().endsWith(".xls") ||
  url.toLowerCase().endsWith(".xlsx") ||
  url.toLowerCase().endsWith(".csv")

export const isLinkVideo = (url: string) =>
  ["youtube.com", "youtu.be"].some((x) => url.toLowerCase().includes(x))

export const isLinkAudio = (url: string) =>
  url.toLowerCase().includes("soundcloud.com")

export const convertToParagraphArray = (content: string) => {
  const resultArray = content.split(/\n{1,}/).filter((s) => s !== "")
  return resultArray
}

export const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => value !== null && value !== undefined
