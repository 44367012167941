import React from "react"

interface CardProps {
  children: React.ReactNode
  className?: string
}

const Card = ({ children, className }: CardProps) => (
  <div className={`card lwb-shadow br2 mv4 ${className ?? ""}`}>{children}</div>
)

export default Card
